import React from 'react'

const ListProdcutService = () => {
  return (
    <div className="container">
      <div
        className="section section-features"
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="800"
      >
        <h4 className="header-text text-center text-bold">
          Products & Services
        </h4>
        <div className="row overflow-hidden">
          <div className="col-md-4">
            <div data-aos="fade-left" data-aos-duraion="1000">
              <div className="card card-blue">
                <div className="icon">
                  <i className="pe-7s-network"></i>
                </div>
                <div className="text">
                  <h4>Installation</h4>
                  <p>
                    บริการติดตั้งวางระบบรักษาความปลอดภัยครบวงจร ทั้ง Hardware
                    และ Software จากแบรนด์ชั้นนำ
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div data-aos="fade-left" data-aos-duration="2000">
              <div className="card card-blue">
                <div className="icon">
                  <i className="pe-7s-users"></i>
                </div>
                <h4>Support</h4>
                <p>
                  บริการหลังการขาย hotline support
                  ที่พร้อมช่วยเหลือคุณทั้งการแนะนำ และ แก้ปัญหา
                  โดยทีมงานมืออาชีพ
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div data-aos="fade-left" data-aos-duration="3000">
              <div className="card card-blue">
                <div className="icon">
                  <i className="pe-7s-browser"></i>
                </div>
                <h4>Management</h4>
                <p>
                  บริหารจัดการความปลอดภัยด้าน IT ได้ทุกที่ทุกเวลา ปกป้อง ตรวจจับ
                  จัดการ อย่างสมบูรณ์และมีประสิทธิภาพ
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListProdcutService
