import React from 'react'

const Map = () => {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3874.356518323748!2d100.39817621483122!3d13.817617890304886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e291126dfe0dd7%3A0x934183080253a938!2z4Lia4Lij4Li04Lip4Lix4LiXIOC5gOC4reC4lOC4seC4muC5gOC4muC4tOC5ieC4peC4ouC4uSDguIvguLTguYDguITguLXguKLguKcg4LiL4Lit4Lif4LiV4LmMIOC4iOC4s-C4geC4seC4lA!5e0!3m2!1sth!2sth!4v1612591154385!5m2!1sth!2sth"
      width="100%"
      height="450"
      frameBorder="0"
      style={{ border: '0' }}
      allowFullScreen=""
      aria-hidden="false"
      tabIndex="0"
    ></iframe>
  )
}

export default Map
