import React from 'react'
import { withPrefix, Link } from 'gatsby'

const Promotion = () => {
  const currentYear = new Date().getFullYear() + 543
  return (
    <div
      className="section section-no-padding"
      data-aos="fade-up"
      style={{ backgroundColor: 'black' }}
    >
      <div className="parallax parallax-promotion" data-color="blue">
        <div className="parallax-background">
          <Link href={withPrefix(`assets/pdf/sophos-firewall-br.pdf`)}>
            <img
              className="parallax-background-image promotion"
              src={withPrefix(
                `assets/img/products/Sophos-XGS-Series-xStream.jpg`
              )}
            />
          </Link>
        </div>
      </div>
      <div className="overflow-hidden">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4 promotion" data-aos="fade-right">
              <div className="visible-md visible-lg">
                <Link href={withPrefix(`assets/pdf/sophos-firewall-br.pdf`)}>
                  <img
                    className="img-responsive center-block"
                    src={withPrefix(`assets/img/products/xg-promotion.png`)}
                    alt="xg-firewall"
                  />
                </Link>
              </div>
              <div className="visible-xs visible-sm">
                <Link href={withPrefix(`assets/pdf/sophos-firewall-br.pdf`)}>
                  <img
                    className="img-responsive center-block"
                    src={withPrefix(`assets/img/products/xg-promotion.png`)}
                    alt="xg-firewall"
                  />
                </Link>
              </div>
            </div>
            <div
              className="col-md-8 promotion__text text-white"
              data-aos="fade-left"
            >
              <div className="description w-100">
                <h2 className="header-text text-bold text-white">
                  !! Promotion !!
                </h2>
                <h4 className="promotion__h4">
                  Sophos XGS Firewall แค่จ่าย MA 2 ปีแรก
                  ได้ทั้งเครื่องไปใช้งานฟรีๆ ฟรี MA 1 ปี
                  <br />
                  และ ฟรี Xstream Protection Subscription !!
                </h4>
                <h5 style={{ lineHeight: '2.7rem' }}>
                  อยากได้ Firewall เร็วและแรง มีครบทุกด้าน ไม่ว่าจะเป็น
                  Anti-virus, Anti-spam, IPS, Web and Application Filter, WAF,
                  VPN การจัดการทุกอย่างในหนึ่งเดียว Best in Security ทั้งยังมี
                  Log และ Report ต้อง Sophos XGS Firewall เท่านั้น
                  <h6>หมดเขต 20 ธันวาคม {currentYear}</h6>
                </h5>
                <Link to="/contact">
                  <button className="hidden-xs btn btn-info btn-md btn-fill center-block animate__animated animate__tada animate__infinite animate__slower">
                    <i className="fa fa-phone" aria-hidden="true"></i>{' '}
                    ต้องการใบเสนอราคา ติดต่อ
                  </button>
                  <button className="visible-xs btn btn-info btn-sm btn-fill center-block animate__animated animate__tada animate__infinite animate__slower">
                    <i className="fa fa-phone" aria-hidden="true"></i>{' '}
                    ต้องการใบเสนอราคา ติดต่อ
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="row black">
            <div className="col-md-12 text-center">
              <h3 className="h3 text-white text-uppercase strong">
                Data sheets
              </h3>
              <div className="col-md-4 h5">
                <Link href={withPrefix(`assets/pdf/sophos-firewall-br.pdf`)}>
                  <i className="fa fa-file-pdf-o" aria-hidden="true"></i> Sophos
                  XGS Series Tech Specs All Models
                </Link>
              </div>
              <div className="col-md-4 h5">
                <Link href={withPrefix(`assets/pdf/sophos-firewall-br.pdf`)}>
                  <i className="fa fa-file-pdf-o" aria-hidden="true"></i> Sophos
                  Firewall Brochure
                </Link>
              </div>
              <div className="col-md-4 h5">
                <Link
                  href={withPrefix(
                    `assets/pdf/sophos-firewall-feature-list.pdf`
                  )}
                >
                  <i className="fa fa-file-pdf-o" aria-hidden="true"></i> Sophos
                  Firewall Feautres List
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Promotion
