import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

const Products = () => {
  const data = useStaticQuery(graphql`
    query {
      endpoint: file(
        relativePath: { eq: "products/Sophos-Endpoint-Protection.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      logo: file(relativePath: { eq: "products/logo-sophos.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      central: file(relativePath: { eq: "products/sophos-central.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const { endpoint, logo, central } = data
  return (
    <div className="section section-demo" data-aos="fade-up">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div
              id="description-carousel"
              className="carousel fade"
              data-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="item">
                  <Img
                    fluid={endpoint.childImageSharp.fluid}
                    fadeIn={true}
                    alt="img"
                  />
                </div>
                <div className="item active">
                  <Img
                    fluid={logo.childImageSharp.fluid}
                    alt="img"
                    fadeIn={true}
                  />
                </div>
                <div className="item">
                  <Img
                    fluid={central.childImageSharp.fluid}
                    fadeIn={true}
                    alt="img"
                  />
                </div>
              </div>
              <ol className="carousel-indicators carousel-indicators-blue">
                <li
                  data-target="#description-carousel"
                  data-slide-to="0"
                  className=""
                ></li>
                <li
                  data-target="#description-carousel"
                  data-slide-to="1"
                  className="active"
                ></li>
                <li
                  data-target="#description-carousel"
                  data-slide-to="2"
                  className=""
                ></li>
              </ol>
            </div>
          </div>
          <div className="col-md-5 col-md-offset-1">
            <h4 className="header-text text-bold text-capitalize">
              Security Solutions
            </h4>
            <p>
              ปิดช่องโหว่จากการบุกรุก รู้เท่าทันภัยไซเบอร์
              เพื่อให้เหยื่อรายต่อไปไม่ใช่องค์กรคุณ
              ระบบรักษาความปลอดภัยเทคโนโลยีสารสนเทศทางออกของทุกปัญหาในโลกไซเบอร์
            </p>
            <Link to="/products">
              <button
                type="button"
                className="hidden-xs btn btn-info btn-fill btn-lg center-block"
              >
                <i className="fa fa-list" aria-hidden="true"></i>
                {'   '}
                รายละเอียดเพิ่มเติม
              </button>
              <button
                type="button"
                className="visible-xs btn btn-info btn-fill btn-xs center-block"
              >
                <i className="fa fa-list" aria-hidden="true"></i>
                {'   '}
                รายละเอียดเพิ่มเติม
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Products
