import React from 'react'
import { withPrefix } from 'gatsby'

const MapImage = () => {
  return (
    <div className="parallax map" data-color="blue">
      <div className="parallax-image map">
        <img src={withPrefix('../assets/img/Map-AW.png')} alt="map" />
      </div>
    </div>
  )
}

export default MapImage
