import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const XGS = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "products/Sophos-XGS-Series.jpg" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  const { fixed } = data.image.childImageSharp
  return (
    <div className="row" data-aos="fade-right">
      <div className="col-md-12">
        <div className="description">
          <h4 className="header-text text-bold text-capitalize text-right visible-sm visible-md visible-lg">
            Sophos Firewall XGS Series
          </h4>
          <h6 className="header-text text-bold text-capitalize visible-xs">
            Sophos Firewall XGS
          </h6>
          <div className="col-md-offset-2 visible-lg visible-md visible-sm">
            <p>
              Next-Gen Firewall Appliances
              <br />
              มาพร้อมกับ ระบบประมาณผล Xstream
              ที่ช่วยเพิ่มประสิทธิภาพการทำงานในระบบแอบพลิเคชั่น
              <br />
              ที่มีฟังชั่นครอบคลุมและทำให้ผู้ใช้งานมองเห็นภาพรวมความปลอดภัยของระบบทั้งหมด
              <br />
              สามารถบริหารจัดการต่อภัยคุกคามต่างๆ ได้โดยสะดวก และเห็นสิ่งต่างๆ
              ที่เกิดขึ้นในระบบเครือข่าย
              <br />
              XGS Firewall เป็นการปฏิวัติทั้งวงการ Firewall และ Endpoint
              Protection
            </p>
          </div>
          <div className="col-md-offset-2 visible-xs">
            <p>
              Next-Gen Firewall Appliances
              <br />
              มาพร้อมกับ ระบบประมาณผล Xstream
              ที่ช่วยเพิ่มประสิทธิภาพการทำงานในระบบแอบพลิเคชั่น
              ที่มีฟังชั่นครอบคลุมและทำให้ผู้ใช้งานมองเห็นภาพรวมความปลอดภัยของระบบทั้งหมด
              สามารถบริหารจัดการต่อภัยคุกคามต่างๆ ได้โดยสะดวก และเห็นสิ่งต่างๆ
              ที่เกิดขึ้นในระบบเครือข่าย
              <br />
              XGS Firewall เป็นการปฏิวัติทั้งวงการ Firewall และ Endpoint
              Protection
            </p>
          </div>
        </div>
        <div className="row align-items-center justify-content-center">
          <div className="col-md-6">
            <Img
              fixed={fixed}
              fadeIn={true}
              className="text-center"
              style={{ borderRadius: '8px' }}
            />
          </div>
          <div className="col-md-6" style={{ marginTop: '5rem' }}>
            <p>
              XGS มีฟีเจอร์เด่น คือ
              <br />
              สามารถซิงโครไนซ์ข้อมูลบนระบบเครือข่ายและอุปกรณ์ปลายทาง (Endpoint)
              เข้าด้วยกันเพื่อยกระดับความปลอดภัยของทั้งเครือข่ายอย่างครบวงจร
              ฟีเจอร์ที่สำคัญของ XGS Firewall คือ Security Heartbeat
              ทำหน้าที่แชร์ข้อมูลความปลอดภัยระหว่างอุปกรณ์ Firewall และ Endpoint
              Protection ของ Sophos เข้าด้วยกันแบบเรียลไทม์
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default XGS
