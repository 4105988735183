import React from 'react'

import EndPoint from '../components/endpoint'
import XGS from './xgs'
import Mobile from '../components/mobile'

const Hero = () => {
  return (
    <div className="section section-presentation">
      <div className="container">
        <EndPoint />
        <XGS />
        <Mobile />
      </div>
    </div>
  )
}

export default Hero
