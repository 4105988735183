import React from 'react'

import MapImage from './mapImage'
import Map from './Maps'

const Contact = () => {
  return (
    <div className="container">
      <div
        className="section section-no-padding"
        data-aos="zoom-in-down"
        data-aos-easing="linear"
        data-aos-duration="800"
      >
        <h4 className="header-text text-center text-bold">Contact us</h4>
        <MapImage />
        <Map />
      </div>
    </div>
  )
}

export default Contact
