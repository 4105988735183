import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../components/layout'
import Parallax from '../components/parallax'
import Partners from '../components/partners'
import Hero from '../components/hero'
import Products from '../components/products'
import ListProdcutService from '../components/card-list-product-service'
import Contact from '../components/contact'
import Promotion from '../components/promotion'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          Home {
            background
            icon
            head
            paragraph
          }
        }
      }
    }
  `)

  return (
    <Layout title="Home">
      <Parallax {...data.site.siteMetadata.Home} />
      <Partners />
      <Hero />
      <Products />
      <Promotion />
      <ListProdcutService />
      <Contact />
    </Layout>
  )
}

export default IndexPage
