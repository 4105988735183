import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Mobile = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "products/Sophos-Mobile-BG.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  const { fixed } = data.image.childImageSharp
  return (
    <div className="row" data-aos="fade-left">
      <div className="col-md-2 visible-xs visible-sm">
        <Img
          className="img-container"
          fixed={fixed}
          style={{
            marginTop: 130,
          }}
        />
      </div>
      <div className="col-md-6">
        <div className="description">
          <h4 className="header-text text-bold text-capitalize">
            Sophos Mobile
          </h4>
          <p>
            เครื่องมือรักษาความปลอดภัยทางไซเบอร์แบบ Unified Endpoint Management
            (UEM) ที่ทำให้ผู้ดูแลระบบสามารถวาง policy ด้านความปลอดภัย
            และบังคับใช้กับ mobile device ของพนักงานในองค์กรได้ทุกคน
            มีระบบแก้ไขปัญหาในกรณีที่ผู้ใช้ละเมิดกฎที่วางไว้อีกด้วย ถ้าใครที่ใช้
            Sophos Central อยู่แล้ว ก็ยิ่งทำงานได้ง่ายขึ้น เพราะ Sophos Mobile
            จะทำงานร่วมกับ Sophos Central
            ทำให้ผู้ดูแลระบบสามารถดูแลพฤติกรรมการใช้งาน
            และควบคุมการใช้งานที่ไม่เหมาะสมได้จากส่วนกลาง โดย Sophos Mobile
            รองรับการทำงานได้ทั้ง IOS, Android, Windows 10 และ MacOS Sophos
            Mobile มีหน้าจอแสดงผลที่เข้าใจง่าย สามารถดูภาพรวมได้ภายในหน้าเดียว
            เช่นเดียวกับการดูรายละเอียดการใช้งานของแต่ละ user ได้เช่นกัน
          </p>
        </div>
      </div>
      <div className="col-md-2 visible-md visible-lg">
        <Img
          className="img-container"
          fixed={fixed}
          style={{
            marginTop: 130,
          }}
        />
      </div>
    </div>
  )
}

export default Mobile
