import React from 'react'
import { withPrefix } from 'gatsby'
import classNames from 'classnames'

const Logos = ({ animate }) => {
  const logoLeft = classNames({
    'left-run': animate,
  })
  const logoRight = classNames({
    'right-run': animate,
  })
  return (
    <ul className="list-unstyled">
      <div className={logoLeft}>
        <li>
          <img
            src={withPrefix(`assets/img/partners/logo-sophos.jpg`)}
            style={{ width: '64px' }}
          />
        </li>
        <li>
          <img src={withPrefix(`assets/img/partners/kaspersky.png`)} />
        </li>
        <li>
          <img src={withPrefix(`assets/img/partners/eset.png`)} />
        </li>
        <li>
          <img src={withPrefix(`assets/img/partners/Symantec-Logo.png`)} />
        </li>
        <li>
          <img src={withPrefix(`assets/img/partners/Trend-Micro-Logo.png`)} />
        </li>
        <li>
          <img src={withPrefix(`assets/img/partners/mcafee-logo.png`)} />
        </li>
        <li>
          <img src={withPrefix(`assets/img/partners/panda.png`)} />
        </li>
        <li>
          <img src={withPrefix(`assets/img/partners/a.png`)} />
        </li>
        <li>
          <img
            src={withPrefix(`assets/img/partners/webroot.png`)}
            style={{ width: '64px' }}
          />
        </li>
      </div>
      <div className={logoRight}>
        <li>
          <img src={withPrefix(`assets/img/partners/wgrd_logo_clr.png`)} />
        </li>
        <li>
          <img src={withPrefix(`assets/img/partners/fortinet-logo.png`)} />
        </li>
        <li>
          <img src={withPrefix(`assets/img/partners/paloalto.png`)} />
        </li>
        <li>
          <img src={withPrefix(`assets/img/partners/cisco.png`)} />
        </li>
        <li>
          <img src={withPrefix(`assets/img/partners/vmware.png`)} />
        </li>
        <li>
          <img src={withPrefix(`assets/img/partners/veeam.png`)} />
        </li>
        <li>
          <i className="fa fa-apple fa-4x"></i>
        </li>
        <li>
          <img src={withPrefix(`assets/img/partners/microsoft.svg`)} />
        </li>
        <li>
          <img src={withPrefix(`assets/img/partners/dell.png`)} />
        </li>
      </div>
      <div className={logoLeft}>
        <li>
          <img src={withPrefix(`assets/img/partners/hp.png`)} />
        </li>
        <li>
          <img src={withPrefix(`assets/img/partners/lenovo.jpg`)} />
        </li>
        <li>
          <img src={withPrefix(`assets/img/partners/asus.png`)} />
        </li>
        <li>
          <img src={withPrefix(`assets/img/partners/rapid7.png`)} />
        </li>
        <li>
          <img src={withPrefix(`assets/img/partners/aruba.svg`)} />
        </li>
        <li>
          <img src={withPrefix(`assets/img/partners/fireeye.png`)} />
        </li>
        <li>
          <img src={withPrefix(`assets/img/partners/nutanix.png`)} />
        </li>
        <li>
          <img src={withPrefix(`assets/img/partners/rackspace.png`)} />
        </li>
        <li>
          <img src={withPrefix(`assets/img/partners/safetica.png`)} />
        </li>
      </div>
      <div className={logoRight}>
        <li>
          <img src={withPrefix(`assets/img/partners/Intel_logo_(2006).svg`)} />
        </li>
        <li>
          <img src={withPrefix(`assets/img/partners/sangfor.png`)} />
        </li>
        <li>
          <img src={withPrefix(`assets/img/partners/forcepoint.png`)} />
        </li>
        <li>
          <img src={withPrefix(`assets/img/partners/brother.png`)} />
        </li>
        <li>
          <img src={withPrefix(`assets/img/partners/canon.png`)} />
        </li>
        <li>
          <img src={withPrefix(`assets/img/partners/adobe.png`)} />
        </li>
        <li>
          <img src={withPrefix(`assets/img/partners/apc.png`)} />
        </li>
      </div>
    </ul>
  )
}

export default Logos
