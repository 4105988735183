import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const EndPoint = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "products/Sophos-central.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  const { fixed } = data.image.childImageSharp
  return (
    <div className="row" data-aos="fade-left">
      <div className="visible-xs visible-sm">
        <Img fixed={fixed} fadeIn={true} />
      </div>
      <div className="col-md-6">
        <div className="description">
          <h4 className="header-text text-bold text-capitalize">
            Sophos Endpoint Protection
          </h4>
          <h5>THE WORLD’S BEST ENDPOINT PROTECTION</h5>
          <p>
            ซอฟต์แวร์สร้างความปลอดภัยให้กับเครื่องคอมพิวเตอร์องค์กร ทำงานรวดเร็ว
            พร้อมความสามารถครอบคลุมตั้งแต่การป้องกันไวรัส (Anti-Virus)
            การควบคุมโปรแกรมต่างๆ การควบคุมเว็บไซต์ที่มีความเสี่ยง
            และการควบคุมอุปกรณ์เชื่อมต่อ ฯลฯ
          </p>
        </div>
      </div>
      <div className="col-md-6 visible-md visible-lg">
        <Img fixed={fixed} fadeIn={true} />
      </div>
    </div>
  )
}

export default EndPoint
