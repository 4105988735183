import React from 'react'

import Logos from './logos'

const Partners = () => {
  return (
    <div className="section section-gray section-clients">
      <div className="container text-center">
        <h6 className="header-text text-bold">Partners</h6>
        <div className="logos frame" data-aos="fade-up">
          <Logos animate={true} />
        </div>
      </div>
    </div>
  )
}

export default Partners
